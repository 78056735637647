import React from 'react'
import Navbar from '../Navbar'

const About = () => {
  return (
    <div className='min-h-screen container mx-auto'>
    <Navbar/>

      <div className='bg-slate-300'>
          <h3>CEO</h3>
      </div>
    



    </div>
  )
}

export default About