import React from 'react'

const Malls = () => {
  return (
    <div>
        This page will contain more info on the malls section
    </div>
  )
}

export default Malls